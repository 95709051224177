import Footer from '../components/footer'
import Card from '../components/card'
import { useEffect, useState } from 'react'
import { db } from '../utils/firebaseUtils'
import { useHistory } from 'react-router-dom'; // Import useHistory for redirection
import '../css/homepage.css'

function Main() {
  const [formations, setFormations] = useState([]);
  const [middleCard, setMiddleCard] = useState(0);
  const history = useHistory(); // Use history for redirection
  const colors = ['purple', 'pink', 'blue'];

  useEffect(() => {
    const getActiveFormations = async () => {
      const formationRef = db.collection('formations');
      const snapshot = await formationRef.where('active', '==', true).get();
      setFormations(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };
    getActiveFormations();
  }, []);

  // const handleCardClick = (index) => {
  //   if (index === middleCard) {
  //     // Redirect when the middle card is clicked
  //     history.push('/some-path'); // Replace '/some-path' with the actual path you want to redirect to
  //   } else {
  //     // Calculate new middle card index and update state
  //     setMiddleCard(index);
  //   }
  // };
  const getPositionClass = (index) => {
    if (index === middleCard) return 'card-middle';
    if ((middleCard + 1) % formations.length === index) return 'card-right';
    if ((middleCard - 1 + formations.length) % formations.length === index) return 'card-left';
    return 'card-outside'; // for cards that are not adjacent to the middle card
  };


  const handleCardClick = (index) => {
    // If the clicked card is already the middle card, redirect.
    if (index === middleCard) {
      history.push(`/formacao${formations[index].url}`);
    } else {
      // Calculate new middle card based on the clicked card.
      setMiddleCard(index);
    }
  };
  
  
  
  const DotsIndicator = ({ total, currentMiddle }) => {
    return (
      <div className="dots-container">
        {Array.from({ length: total }).map((_, index) => (
          <div key={index} className={`dot ${index === currentMiddle ? 'active' : ''}`}></div>
        ))}
      </div>
    );
  };
  
  



  return (
    <div className="parent-container">
      <div className={`homepage-container ${formations.length > 0 ? 'homepage-container-with-formations' : 'homepage-container-no-formations'}`}>
        <div className='col-md-12 my-3' id='landing-page-title'>
          <div className='center'>
            <h2 className={`title ${formations.length > 0 ? 'title-with-formations' : 'title-no-formations'}`}>
              Formações Certificadas
            </h2>
          </div>
          <div className='center'>
            {formations.length > 0 ? (
              <h4>
                Não percas esta oportunidade
                <br />
                de investires no teu futuro.
              </h4>
            ) : (
              <h4>
                Estamos a preparar as melhores
                <br />
                formações para ti.
              </h4>
            )}
            {formations.length > 0 && (
              <h3 className='texto-inscrever'>Inscreve-te já!</h3>
            )}
          </div>
        </div>
        <div className= {`${formations.length > 0 ? 'buttons row' : 'no-formations'}`}>
          <div className='row align-items-center justify-content-center' id='landing-page-cards'>
            {formations.map((formation, index) => {
              const isMiddleCard = index === middleCard;
              return (
                <div className={`card-container ${getPositionClass(index)}`} key={formation.id} onClick={() => handleCardClick(index)}>
                    <Card
                    img={formation.img}
                    name={formation.name}
                    date={formation.day}
                    startTime={formation.startTime}
                    endTime={formation.endTime}
                    price={formation.price}
                    color={colors[index % colors.length]}
                    link={formation.url}
                    isMiddleCard={isMiddleCard}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <DotsIndicator total={formations.length} currentMiddle={middleCard} />
        {formations.length === 0 && (
          <div className='col-md-12'>
            <h4 className='texto-fica-atento'>Fica atento/a!</h4>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}  

export default Main;

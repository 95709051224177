// import Footer from '../components/footer' //footer should be added in main file

import Card from '../components/card'
import InscModal from '../components/inscModal'
import ConfirmationModal from '../components/confirmationModal';
import RejectionModal from '../components/rejectionModal';
import ErrorModal from '../components/errorModal';
import Footer from '../components/footer'
import { simplifiedDate } from '../utils/formationUtils'
import { useEffect, useState, useRef } from 'react'
import * as firebase from '../utils/firebaseUtils'
import Sponsor from '../components/sponsor'
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore'
import { db } from '../utils/firebaseUtils'
// import localImage from '../img.jpg'

function FormationPage(props) {
  const sponsorsCollectionRef = useRef(collection(firebase.db, 'sponsors')).current;
  const [sponsors, setSponsors] = useState([])
  const [url, setUrl] = useState({})
  const [image, setImage] = useState({})
  const [open, setOpen] = useState(props.data.open)
  let formationSponsors = []

  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [rejectionMessage, setRejectionMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [full, setFull] = useState(false);

  useEffect(() => {
    const getSponsors = async () => {
      const data = await getDocs(sponsorsCollectionRef)
      setSponsors(data.docs.map(doc => ({ ...doc.data(), id: doc.id })))
    }

    const getProgram = async () => {
      if (props.data.program) {
        try {
          const programUrl = await firebase.storage
            .ref(props.data.program)
            .getDownloadURL()
          setUrl(programUrl)
        } catch (error) {
          console.error('Error getting program URL: ', error)
        }
      }
    }

    const getImage = async () => {
      if (props.data.img) {
        try {
          const imageUrl = await firebase.storage.ref(props.data.img).getDownloadURL();
          setImage(imageUrl);
        } catch (error) {
          console.error("Error getting image URL: ", error);
        }
      }
    };


    const updateOpen = async () => {
      if (open && new Date() > new Date(props.data.endInscDay + 'T23:59:59')) {
        const docRef = doc(db, 'formations', props.data.id)
        await updateDoc(docRef, {
          open: false
        })
        setOpen(false)
      }
    }

    const handleSpace = async () => {
      if (await firebase.getFilledSpace(props.data.id) === props.data.numberOfSpots) {
        setFull(true);
      }
    }

    getProgram()
    getImage()
    getSponsors()
    updateOpen()
    handleSpace()
  }, [props.data, open, sponsorsCollectionRef])

  function merge() {
    let m;
    props.data.sponsors.forEach(sponsorID => {
      sponsors.forEach(sponsor => {
        if (sponsor.id === sponsorID) {
          m = {
            img: sponsor.img,
            description: sponsor.description
          };
          formationSponsors.push(m);
        }
      });
    });
  }
  merge()


  return (
    <>
      <div className='container-formations'>
        <div
          className='container context gradient-container'
          id='formation-page'
        >
          <div className='row align-items-center justify-content-center'>
              <div className='two-cards'>
                <div className='formacao-information col-12 col-lg-6 col-xl-6'>
                  <div className='align-items-center justify-content-center'>
                    <div className='intro mb-4'>
                      <h4>Formação Certificada</h4>
                      <h2 className='mb-3'>{props.data.name}</h2>
                      {props.data.smallDescription.map((description, index) => {
                        return <p key={index}>{description}</p>
                      })}
                    </div>
                    <hr />
                    <div className='' id='pm-place-time'>
                      <h5 className="mt-3">
                        <strong>LOCAL</strong>
                      </h5>
                      <p>A formação será realizada em {props.data.place} </p>
                      {props.data.place === 'formato online' ? (
                        <p>
                          O link será, antecipadamente, enviado por email para
                          todos os inscritos.
                        </p>
                      ) : null}
                      <div className="row d-flex">
                        <div className="col-12 col-xl-6">
                          <h5 className="mt-3">
                            <strong>HORÁRIO</strong>
                          </h5>
                          <p>{simplifiedDate(props.data.day)}</p>
                          <p>
                            {props.data.startTime}-{props.data.endTime}
                          </p>
                        </div>
                        <div className="col-12 col-xl-6 align-self-end mt-3">
                          {
                            (() => {
                              return props.data.open && !full ? (
                                <div className="row g-0 justify-content-center ">
                                  <button
                                    onClick={() => {
                                      setModalOpen(true);
                                    }}
                                    className={"insc-button application-btn"/*insc-button-" + props.color*/}
                                  >
                                    <strong>Inscrição na formação</strong>
                                  </button>
                                </div>
                              ) : (
                                <></>
                              );
                            })()
                          }
                          {modalOpen ? <InscModal setModalOpen={setModalOpen} setConfirmationMessage={setConfirmationMessage}
                            setRejectionMessage={setRejectionMessage} setErrorMessage={setErrorMessage}
                            setPaymentData={setPaymentData} formation={props.data.id} formationName={props.data.name}
                            startTime={props.data.startTime} endTime={props.data.endTime} date={props.data.day} price={props.data.price}
                            numberOfSpots={props.data.numberOfSpots}
                          /> : null}
                          {confirmationMessage ?
                            <ConfirmationModal suggestion="Agile & Scrum" setConfirmationMessage={setConfirmationMessage}
                              paymentData={paymentData} /> : null}
                          {rejectionMessage ? <RejectionModal suggestion="Agile & Scrum" setRejectionMessage={setRejectionMessage} /> : null}
                          {errorMessage ? <ErrorModal suggestion="Agile & Scrum" setErrorMessage={setErrorMessage} /> : null}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className='col-12 col-lg-6 col-xl-6'>
                  <Card
                    className='new-card-container--large'
                    id={props.data.id}
                    name={props.data.name}
                    maxSpots={props.data.numberOfSpots}
                    img={props.data.img}
                    title={props.data.name}
                    date={props.data.day}
                    startTime={props.data.startTime}
                    endTime={props.data.endTime}
                    price={props.data.price}
                    endInsc={props.data.endInscDay}
                    color={props.color}
                  />
                </div>
              </div>
          </div>
        </div>
        <div className='container' id='descricao'>
          <div className='row justify-content-center' id='descricao-row'>
            <div className='col-md-8'>
              <div className='description'>
                {props.data.largeDescription.map((desc, index) => {
                  return <p key={index}>{desc}</p>
                })}
                <p>
                  Poderás consultar aqui o
                  <a
                    className={'programa-button'}
                    href={url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <strong> programa</strong>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className='last-information'>
            <div className='two-cards'>
              <div className='first-card'>
                <ul>
                  <li>
                    <strong>Prazo limite de inscrição:</strong>{' '}
                    <br />
                    <p>
                      {simplifiedDate(props.data.endInscDay)}!
                    </p>
                  </li>
                  <li>
                    <strong>Preço</strong>
                    <br />
                    <p>
                      {props.data.price}€{' '}
                    </p>
                  </li>
                  <li>
                    <strong>Atenção - as vagas são limitadas!</strong>
                    <p>
                      Em caso de dúvida, não hesites em contactar-nos através de{' '}
                      <ins>
                        <a className='mail' href='mailto:formacoes@junifeup.pt'>
                          formacoes@junifeup.pt.
                        </a>
                      </ins>
                    </p>
                    <p>
                      Termos & Condições: A JuniFEUP não poderá devolver o
                      dinheiro em caso de desistência. As vagas são preenchidas por
                      ordem de pagamento das inscrições.
                    </p>
                  </li>
                </ul>
              </div>
              <div className='formacao-last-information'>
                <h4 className='header-text'>
                  Formação certificada em {props.data.name} fornecida por:
                </h4>
                {formationSponsors.map(sponsor => {
                  return (
                    <div
                      className='organizadores'
                      key={sponsor.id}
                    >
                      <p><Sponsor data={sponsor} /></p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default FormationPage

import { useState } from 'react';
import * as firebase from '../utils/firebaseUtils';
import { addHours, addMinutes, getPreviousDay } from "../utils/formationUtils";
import { payment } from "../utils/easypayUtils";
import '../css/modal.css';
import axios from 'axios';

function InscModal(props) {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [studentNumber, setStudentNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [idCardNumber, setIdCardNumber] = useState('');
  const [idCardExpirationDate, setIdCardExpirationDate] = useState('');
  const [nif, setNIF] = useState('');
  const [institution, setInstitution] = useState('');
  const [course, setCourse] = useState('');
  const [year, setYear] = useState('');
  const [address, setAddress] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [referral, setReferral] = useState('');
  const [newsletter, setNewslettter] = useState(true);
  const [paymentProof, setPaymentProof] = useState({});

const sendNotificationEmail = async (user) => {
  let params = {
    to_email: user.email,
    name: user.fullName,
    formation: props.formationName,
    date: props.date,
    begin_hour: props.startTime,
    end_hour: props.endTime,
    value: props.price,
    valid_days: "3"
  };

  try {
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/mail/sendInscEmail`, { params });
  } catch (error) {
    console.error('Error sending email:', error);
  }
}


  const changePage = async (error, user) => {
    if (!error && user) {
      firebase.registerUser(user, props.formation);
      await sendNotificationEmail(user);
      props.setModalOpen(false);
      props.setConfirmationMessage(true);
    } else {
      console.log(error);
      props.setModalOpen(false);
      props.setErrorMessage(true);
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    const forms = document.getElementsByClassName('needs-validation');
    Array.prototype.filter.call(forms, async function (form) {
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      else {
        // const expireDate = addHours(72) < getPreviousDay(props.date) ? addHours(72) : getPreviousDay(props.date)
        const expireDate = addMinutes(new Date(), 5);
        const registration = {
          email,
          fullName,
          studentNumber,
          phoneNumber,
          nif,
          birthDate,
          idCardNumber,
          idCardExpirationDate,
          institution,
          course,
          year,
          address,
          zipcode,
          referral,
          newsletter,
          registrationExpiration: expireDate,
          sentReminderEmail: false,
          sentExpirationEmail: false,
          paid: false
        };
        if (await firebase.getFilledSpace(props.formation) < props.numberOfSpots) {
          firebase.uploadFilesToStorage(props.formation, paymentProof, "proof", false, email);
          // await payment(registration, props.formationName, props.price, changePage);
          changePage(null, registration);
        } else {
          props.setModalOpen(false);
          props.setRejectionMessage(true);
        }
      }
      form.classList.add('was-validated');
    }, false);
  }
  return (
    <div className="popup popup-with-backcolor aux">
      <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" data-dismiss="modal" onClick={() => props.setModalOpen(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
            <section id="modal-registration">
              <div className="container">
                <h2 className="title-padding title-mobile p-top-2">
                  <strong>Inscrição</strong>
                </h2>
                <p style={{ fontSize: '16px' }}>
                  Formação certificada pela APGEI (exclusivo para alunos do
                  Ensino Superior).
                </p>
                <p style={{ fontSize: '16px' }}>
                  O custo da formação é de {props.price}€.
                </p>
                <p style={{ fontSize: '16px' }}>
                  Podes <strong>efetuar o pagamento</strong> por transferência bancária através do <strong>IBAN PT50 0036 0073 9910 0041 0678 7 </strong> e nome: <strong>JUNIFEUP-JUNIOR EMPRESA U PORTO </strong> 
                   ou por <strong>MB Way</strong> para um dos seguintes contactos: <strong>932047792</strong> (Mário Branco); <strong>910137701</strong> (Rita Pereira).
                </p>
                <p style={{ fontSize: '16px' }}>
                  Independentemente do método de pagamento <strong>coloca na descrição o endereço de E-mail e Nome que usaste na inscrição</strong>.
                </p>
                <p style={{
                  fontSize: '16px',
                  paddingBottom: '10px'
                }}>
                  Caso tenhas alguma questão, contacta-nos através de: <ins>formacoes@junifeup.pt</ins>
                </p>
                <small className="text-muted">
                  *Caso a inscrição seja feita no dia anterior ou no próprio dia limite à inscrição, o pagamento tem que
                  ser feito no máximo até ao dia anterior à Formação.
                </small>
                <br />
                <small className="text-muted">
                  *Tem em atenção que o email com os detalhes de pagamento pode ir para a pasta spam.
                </small>
                <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate={true} id="registerForm">
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-12 col-lg-6 label-input">
                        <label htmlFor="fullName"><strong>Nome Completo</strong> <span className="required-label">*</span></label>
                        <input type="text" value={fullName} onChange={e => setFullName(e.target.value)}
                          className="form-control custom-input" id="fullName" placeholder="Introduz o teu nome completo"
                          required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-12 col-lg-6 label-input">
                        <label htmlFor="emailReq"><strong>Endereço de E-mail</strong> <span className="required-label">*</span></label>
                        <input type="email" value={email} onChange={e => setEmail(e.target.value)}
                          className="form-control custom-input" id="emailReq" placeholder="Introduz o teu e-mail"
                          required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-12 col-lg-4 mt-2 label-input">
                        <label htmlFor="studentNumber"><strong>Número de Estudante</strong> <span className="required-label">*</span></label>
                        <input type="number" value={studentNumber} onChange={e => setStudentNumber(e.target.value)}
                          className="form-control custom-input" id="studentNumber" placeholder="Número de estudante" pattern="^[0-9]+$" min="0" required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-12 col-lg-4 mt-2 label-input">
                        <label htmlFor="phoneNumber"><strong>Telemóvel</strong> <span className="required-label">*</span></label>
                        <input type="number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}
                          className="form-control custom-input" id="phoneNumber" placeholder="Telemóvel" pattern="^[0-9]+$" min="0" required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-12 col-lg-4 mt-2 label-input">
                        <label htmlFor="birthDate"><strong>Data de Nascimento</strong> <span className="required-label">*</span></label>
                        <input type="date" value={birthDate} onChange={e => setBirthDate(e.target.value)}
                          className="form-control custom-input" id="birthDate" placeholder="Data de Nascimento" required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-12 col-lg-4 mt-2 label-input">
                        <label htmlFor="IDCard"><strong>Cartão de Cidadão</strong> <span className="required-label">*</span></label>
                        <input type="text" value={idCardNumber} onChange={e => setIdCardNumber(e.target.value)}
                          className="form-control custom-input" id="IDCard" placeholder="Nº de Cartão de Cidadão" pattern="^[0-9]+$"
                          required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-12 col-lg-4 mt-2 label-input">
                        <label htmlFor="IDValDate"><strong>Data de Validade</strong> <span className="required-label">*</span></label>
                        <input type="date" value={idCardExpirationDate}
                          onChange={e => setIdCardExpirationDate(e.target.value)} className="form-control custom-input"
                          id="IDValDate" placeholder="Validade Cartão Cidadão"
                          required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-12 col-lg-4 mt-2 label-input">
                        <label htmlFor="NIF"><strong>NIF</strong> <span className="required-label">*</span></label>
                        <input type="number" value={nif} onChange={e => setNIF(e.target.value)} className="form-control custom-input" pattern="^[0-9]+$" min="0"
                          id="NIF" placeholder="Introduz o teu NIF" required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-12 col-lg-4 mt-2 label-input">
                        <label htmlFor="school"><strong>Instituição de Ensino</strong> <span className="required-label">*</span></label>
                        <input type="text" value={institution} onChange={e => setInstitution(e.target.value)}
                          className="form-control custom-input" id="school" placeholder="Instituição de Ensino"
                          required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-12 col-lg-4 mt-2 label-input">
                        <label htmlFor="course"><strong>Curso</strong> <span className="required-label">*</span></label>
                        <input type="text" value={course} onChange={e => setCourse(e.target.value)}
                          className="form-control custom-input" id="course" placeholder="Curso" required />
                        <div className="invalid-feedback">Obrigatório</div>
                      </div>
                      <div className="col-md-12 col-lg-4 mt-2 label-input">
                        <label htmlFor="grade"><strong>Ano Curricular</strong> <span className="required-label">*</span></label>
                        <input type="number" value={year} onChange={e => setYear(e.target.value)} min="1" pattern="^[0-9]+$"
                          className="form-control custom-input" id="grade" placeholder="Ano Curricular"
                          required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-12 col-lg-8 label-input">
                        <label htmlFor="address"><strong>Morada</strong> <span className="required-label">*</span></label>
                        <input type="text" value={address} onChange={e => setAddress(e.target.value)}
                          className="form-control custom-input" id="address" placeholder="Introduz a tua morada"
                          required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                      <div className="col-md-12 col-lg-4 label-input">
                        <label htmlFor="zipcode"><strong>Código Postal</strong> <span className="required-label">*</span></label>
                        <input type="text" value={zipcode} onChange={e => setZipcode(e.target.value)}
                          className="form-control custom-input" id="zipcode" placeholder="Introduz o teu código postal"
                          required />
                        <div className="invalid-feedback">Inválido!</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group paymentRadio" >
                    <div className="custom-control payment-div">
                      <div className="row d-flex">
                        <div className="col-md-12 col-lg-6 label-input" style={{ paddingLeft: '0' }}>
                          <label htmlFor="paymentProof"><strong>Comprovativo de Pagamento:</strong> <span className="required-label">*</span></label>
                          <input type="file" className="form-control" id="paymentProof" accept=".pdf,application/pdf"
                                required onChange={(e) => setPaymentProof(e.target.files[0])}/>
                            <span className="file-message">
                                    {paymentProof ? <u>{paymentProof.name}</u> : 'Nenhum arquivo selecionado.'}
                            </span>
                            <div className="invalid-feedback" id="img-feedback">
                              O comprovativo de pagamento é obrigatório!
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 label-input" style={{ paddingLeft: '0' }}>
                          <label htmlFor="referralSelect"><strong>Como tomaste conhecimento da formação?</strong> <span className="required-label">*</span></label>
                          <select className="custom-select custom-input" id="referralSelect" onChange={(e) => setReferral(e.target.value)} required>
                            <option value="" disabled selected>Seleciona uma opção</option>
                            <option value="Facebook da JuniFEUP">Facebook da JuniFEUP</option>
                            <option value="Cartaz afixado">Cartaz afixado</option>
                            <option value="Entidades parceiras">Entidades parceiras</option>
                            <option value="Instagram da JuniFEUP">Instagram da JuniFEUP</option>
                            <option value="Panfleto">Panfleto</option>
                            <option value="Email de Divulgação">Email de Divulgação</option>
                            <option value="LinkedIn da JuniFEUP">LinkedIn da JuniFEUP</option>
                            <option value="Amigos/Colegas/Familiares">Amigos/Colegas/Familiares</option>
                          </select>
                          <div className="invalid-feedback">Obrigatório!</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group optionCheck align-items-center justify-content-center">
                    <div className="custom-control custom-checkbox">
                      <div className="row justify-content-center">
                        <input type="checkbox" name="optionCheck" id="juniEmails" className="custom-control-input"
                          defaultChecked={newsletter} onChange={() => setNewslettter(!newsletter)} />
                        <label className="custom-control-label" htmlFor="juniEmails">Aceito receber divulgações acerca
                          de
                          oportunidades futuras da JuniFEUP</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    <button type="submit" className="btn modal-btn">
                      <strong>Inscrever</strong>
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}


export default InscModal;
import {BrowserRouter as Router, Route, Switch,} from 'react-router-dom';
import NavBar from './components/navbar';

import './css/myCss.css';
import './css/landing-page.css';

import MainPage from './pages/main'
import {useEffect, useState} from "react";
import FormationPage from "./pages/formationPage";
import Login from "./pages/login";
import {AuthProvider} from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import BackOffice from "./pages/backOffice";
import AddFormation from "./pages/addFormation";
import {db} from "./utils/firebaseUtils";
import {collection, getDocs} from "firebase/firestore";
import * as firebase from "./utils/firebaseUtils";
import EditFormation from "./pages/editFormation";
import Sponsors from "./pages/sponsors";
import AddSponsor from "./pages/addSponsor";
import EditSponsor from "./pages/editSponsor";
import Participants from "./pages/participants";
import NotFound from "./pages/NotFound";

function App() {
  const formationRef = db.collection('formations');
  const sponsorsCollectionRef = collection(firebase.db, "sponsors");
  const [formations, setFormations] = useState([]);
  const [activeFormations, setActiveFormations] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const colorsClass = ['purple', 'pink', 'blue'];
  let i = 0;

  useEffect(() => {
    const getFormations = async () => {
      const snapshot = await formationRef.orderBy("day", "desc").get();
      setFormations(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }

    const getActiveFormations = async () => {
      const snapshot = await formationRef.where('active', '==', true).get();
      setActiveFormations(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }

    const getSponsors = async () => {
      const d = await getDocs(sponsorsCollectionRef);
      setSponsors(d.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }

    getFormations();
    getActiveFormations();
    getSponsors();
  }, []);

  return (
    <div className="landing-page">
      <AuthProvider>
      <Router>
          <NavBar/>
          <Switch>
            <Route exact path="/">
              <MainPage/>
            </Route>
            {
              activeFormations.map((formation) => {
                i++
                if(i === 3) i=0
                if (formation !== []) {
                  return (
                    <Route exact path={"/formacao" + formation.url} key={formation.id}>
                      <FormationPage data={formation} color={colorsClass[i]}/>
                    </Route>
                  )
                }
              })
            }
            <Route exact path="/login">
              <Login/>
            </Route>
            <PrivateRoute exact path="/backOffice">
              <BackOffice/>
            </PrivateRoute>
            {
              sponsors.length !== 0 ? <PrivateRoute exact path="/adicionarFormacao">
                <AddFormation sponsors={sponsors}/>
              </PrivateRoute> : null
            }
            {
              formations.map((formation) => {
                return (
                  <PrivateRoute exact path={"/participantes" + formation.url} key={formation.id}>
                    <Participants />
                  </PrivateRoute>
                )
              })
            }
            {
              sponsors.length !== 0 ?
              formations.map((formation) => {
                return (
                  <PrivateRoute exact path={"/editarFormacao" + formation.url} key={formation.id}>
                    <EditFormation data={formation} sponsors={sponsors}/>
                  </PrivateRoute>
                )
              }) : null
            }
            <PrivateRoute exact path="/organizadores">
              <Sponsors/>
            </PrivateRoute>
            <PrivateRoute exact path="/adicionarOrganizador">
              <AddSponsor/>
            </PrivateRoute>
            {
              sponsors.map((sponsor) => {
                return (
                  <PrivateRoute exat path={"/editarOrganizador" + sponsor.id} key={sponsor.id}>
                    <EditSponsor data={sponsor} />
                  </PrivateRoute>
                )
              })
            }
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
      </Router>
    </AuthProvider>
  </div>
  );
}

export default App;

import { simplifiedDate } from "../utils/formationUtils";
import * as firebase from "../utils/firebaseUtils";
import { useEffect, useState } from "react";
import styles from "../css/card.css";

function NewCard(props) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      if (props.img) {
        try {
          const url = await firebase.storage.ref(props.img).getDownloadURL()
          setImage(url)
        } catch (error) {
          console.error('Error getting image URL:', error)
        }
      }
    }

    getImage()
  }, [props.img])

  const containerClasses = `new-card-container ${props.className || ''}`;

  const cardStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const cardContent = (
    <div className={containerClasses}  style={cardStyle}>
      <div className="new-card-body">
        <div className="new-card-title">
          <strong>{props.name}</strong>
          {/* <span className={`new-card-tag new-card-tag-${props.color}`}>novo</span> */}
        </div>
        <ul className="new-card-info">
          <div className="card-date-info">
            <div className="date">
              <i
                className={`bi bi-calendar-check new-card-icon new-card-icon-${props.color}`}
              ></i>
              <div className="date-text">
                {simplifiedDate(props.date)}
              </div>
            </div>
            <div className="time">
              <i
                className={`bi bi-clock new-card-icon new-card-icon-${props.color}`}
              ></i>
              <div className="time-text">
                {props.startTime}-{props.endTime}
              </div>
            </div>
          </div>
            <li>
            <div className="circle-price">
              <div className="text">
                {props.price}
              </div>
              <i
                className={`bi bi-currency-euro new-card-icon new-card-icon-${props.color}`}
              ></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );

  return props.isMiddle ? (
    <a href={"/formacao" + props.link} className="new-card-link">
      <div className="new-card-container" style={cardStyle}>
        <div className="new-card-body">
          <div className="new-card-title">
            <strong>{props.name}</strong>
            {/* <span className={`new-card-tag new-card-tag-${props.color}`}>novo</span> */}
          </div>
          <ul className="new-card-info">
            <li>
              <i
                className={`bi bi-calendar-check-fill new-card-icon new-card-icon-${props.color}`}
              ></i>
              {simplifiedDate(props.date)}
            </li>
            <li>
              <i
                className={`bi bi-clock-fill new-card-icon new-card-icon-${props.color}`}
              ></i>
              {props.startTime}-{props.endTime}
            </li>
            <li>
              <div className="circle-price">
                {props.price}
                <i
                  className={`bi bi-currency-euro new-card-icon new-card-icon-${props.color}`}
                ></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </a>
  ) : (
    cardContent
  );
}

export default NewCard;

import * as firebase from "../utils/firebaseUtils";
import {useEffect, useState} from "react";

function Sponsor(props) {
  const [image, setImage] = useState([]);

  useEffect(() => {
    const getImage = async () => {
      if (props.data && props.data.img) {
        try{
          await firebase.storage.ref(props.data.img).getDownloadURL().then(url => {
            setImage(url);
          });
        } catch (error) {
          console.error("Failed to load image:", error);
          setImage(""); 
        }
      }
      else {
        setImage({
          url: "",
        });
      }
    };
  
    getImage();
  }, [props.data]);
  

  return (
      <>
        <div className="organizadores-logo">
          <img className="company_img img-fluid" alt="" src={image} />
        </div>
        <div className="description">
          <p>
            {props.data.description}
          </p>
        </div>
      </>
  )
}

export default Sponsor;
import Footer from "../components/footer";
import {useState} from "react";
import OfficeModal from "../components/officeModal";
import {addFormation, uploadFilesToStorage, urlExists} from "../utils/firebaseUtils";
import {useHistory} from "react-router-dom";
import {buildURL, isAllFalse, stringToArray, isOpen} from "../utils/formationUtils";
import Sponsor from "../components/sponsor";

function AddFormation(props) {
  const [name, setName] = useState('');
  const [place, setPlace] = useState('');
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [limitDate, setLimitDate] = useState('');
  const [price, setPrice] = useState('');
  const [spots, setSpots] = useState('');
  const [smallDescription, setSmallDescription] = useState('');
  const [bigDescription, setBigDescription] = useState('');
  const [active, setActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [program, setProgram] = useState({});
  const [img, setImage] = useState({});
  const [checkedState, setCheckedState] = useState(
    new Array(props.sponsors.length).fill(false)
  );
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    const forms = document.getElementsByClassName('needs-validation');
    Array.prototype.filter.call(forms, async function(form) {
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      else if(!document.getElementById('smallDesc').value.match('^(?!.*<[^>]+>).*')) {
        document.getElementById('smallDesc').style.borderColor = "#dc3545";
        document.getElementById('smallDesc-feedback').style.display = "inline";
        e.preventDefault();
        e.stopPropagation();
      }
      else if(!document.getElementById('bigDesc').value.match('^(?!.*<[^>]+>).*')) {
        document.getElementById('bigDesc').style.borderColor = "#dc3545";
        document.getElementById('bigDesc-feedback').style.display = "inline";
        e.preventDefault();
        e.stopPropagation();
      }
      else if (img.type.split('/')[0] !== "image"){
        document.getElementById('img').style.borderColor = "#dc3545";
        document.getElementById('img-feedback').style.display = "inline";
        e.preventDefault();
        e.stopPropagation();
      }
      else if (program.type !== "application/pdf"){
        document.getElementById('program').style.borderColor = "#dc3545";
        document.getElementById('program-feedback').style.display = "inline";
        e.preventDefault();
        e.stopPropagation();
      }
      else if(isAllFalse(checkedState)){
        document.getElementById('sponsor-feedback').style.display = "inline";
        e.preventDefault();
        e.stopPropagation();
      }
      else {
        setModalInfo({
          title: 'Adicionar Formação',
          text: 'Tens a certeza que queres adicionar a formação?',
          actionFunc: add,
        })
        setModalOpen(true);
      }
      form.classList.add('was-validated');
    
    }, false);
  }

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };

  const add = async () => {
    const formation = {
      name: name,
      place: place,
      day: date,
      endInscDay: limitDate,
      active: active,
      open: isOpen(limitDate, active),
      endTime: endTime,
      startTime: startTime,
      price: parseFloat(price),
      numberOfSpots: parseInt(spots),
      url: buildURL(date, name),
      smallDescription: stringToArray(smallDescription),
      largeDescription: stringToArray(bigDescription),
    };
    formation.sponsors = [];
    checkedState.map((state, index) => {
      if(state === true) {
        formation.sponsors.push(props.sponsors[index].id)
      }
    })
    const exists = await urlExists(formation.url);
    if(exists !== 0) {
      formation.url = formation.url + "_" + exists;
    }

    const id = await addFormation(formation);
    uploadFilesToStorage(id, img, "img", false);
    uploadFilesToStorage(id, program, "program", false);

    history.push('/backOffice')
  };

  return (
    <div>
      <div className="container context gradient-container" id="addFormation-page">
        <div className="row align-items-center justify-content-center">
            <div className="col-10 formacao-intro">
              <div className="row align-items-center justify-content-center mt-5 container-addFormation">
                <form className="needs-validation" noValidate={true} id="addFormationForm" onSubmit={(e) => handleSubmit(e)}>
                  <div className="form-group optionCheck">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-7 label-input">
                        <h2>Adicionar Formação</h2>
                      </div>
                      <div className="col-md-5 form-check check-div">
                        <input className="form-check-input" type="checkbox" name="optionCheck" id="activateFormation"
                               defaultChecked={active} onChange={() => setActive(!active)}/>
                          <label htmlFor="activateFormation">
                            Ativar formação
                          </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col label-input">
                        <label htmlFor="name">Nome</label>
                        <input type="text" value={name} onChange={e => setName(e.target.value)}
                               className="form-control" id="name" placeholder="Nome da formação"
                               pattern="^[a-zA-Z\s]*$"
                               required/>
                        <div className="invalid-feedback">
                          Nome é obrigatório, e só pode conter letras e/ou espaços!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col label-input">
                        <label htmlFor="place">Local</label>
                        <input type="text" value={place} onChange={e => setPlace(e.target.value)}
                               className="form-control" id="place" placeholder="Local da formação"
                               pattern="^(?!.*<[^>]+>).*"
                               required/>
                        <div className="invalid-feedback">
                          Local é obrigatório!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="date">Data</label>
                        <input type="date" value={date} onChange={e => setDate(e.target.value)}
                               className="form-control" id="date"
                               required/>
                        <div className="invalid-feedback">
                          Data é obrigatória!
                        </div>
                      </div>
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="startTime">Horário de início</label>
                        <input type="time" value={startTime} onChange={e => setStartTime(e.target.value)}
                               className="form-control" id="startTime" required/>
                        <div className="invalid-feedback">
                          Hora de início é obrigatória!
                        </div>
                      </div>
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="endTime">Horário de término</label>
                        <input type="time" value={endTime} onChange={e => {setEndTime(e.target.value)}}
                               className="form-control" id="endTime" min={startTime}
                               required/>
                        <div className="invalid-feedback">
                          Hora de término é obrigatória e tem que ser depois da hora de início!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="limitDate">Inscrições até</label>
                        <input type="date" value={limitDate} onChange={e => setLimitDate(e.target.value)}
                               className="form-control" id="limitDate" max={date}
                               required/>
                        <div className="invalid-feedback">
                          Data limite de inscrições é obrigatório e tem que ser antes da data da formação!
                        </div>
                      </div>
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="price">Preço</label>
                        <input type="number" value={price} onChange={e => setPrice(e.target.value)}
                               className="form-control" id="price" placeholder="Preço" required/>
                        <div className="invalid-feedback">
                          Preço é obrigatório!
                        </div>
                      </div>
                      <div className="col-md-4 mt-2 label-input">
                        <label htmlFor="spots">Número de vagas</label>
                        <input type="number" value={spots} onChange={e => setSpots(e.target.value)}
                               className="form-control" id="spots" placeholder="Número de vagas"
                               min='1' pattern="^[0-9]*$"
                               required/>
                        <div className="invalid-feedback">
                          Número de vagas é obrigatório!
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    <div className="form-group">
                      <div className="row d-flex justify-content-center">
                        <div className="col label-input">
                          <label htmlFor="smallDesc">Pequena Descrição</label>
                          <textarea className="form-control" id="smallDesc" rows="5" onChange={e => setSmallDescription(e.target.value)} required/>
                          <div className="invalid-feedback" id="smallDesc-feedback">
                            Pequena Descrição é obrigatória e não pode contar tags de html!
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {
                    <div className="form-group">
                      <div className="row d-flex justify-content-center">
                        <div className="col label-input">
                          <label htmlFor="bigDesc">Grande Descrição</label>
                          <textarea className="form-control" id="bigDesc" rows="10" onChange={e => setBigDescription(e.target.value)} required/>
                          <div className="invalid-feedback" id="bigDesc-feedback">
                            Grande Descrição é obrigatória e não pode contar tags de html!
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="form-group">
                    <div className="row d-flex justify-content-center">
                      <div className="col">
                        <label>Imagem</label>
                          <div className="buttons-div" id="addFormation-file">
                            <label htmlFor="img" className="custom-file-upload">
                              <b>Escolher Ficheiro</b>
                            </label>
                            <input type="file" className="form-control" id="img"
                                required onChange={(e) => setImage(e.target.files[0])}/>
                            <span className="file-message">
                                    {img ? <u>{img.name}</u> : 'Nenhum arquivo selecionado.'}
                            </span>
                            <div className="invalid-feedback" id="img-feedback">
                              Imagem é obrigatória!
                            </div>
                          </div>
                          <small>Tamanho recomendado: 332x216</small>
                      </div>
                      <div className="col">
                        <label>Programa</label>
                          <div className="buttons-div" id="addFormation-file">
                            <label htmlFor="program" className="custom-file-upload">
                              <b>Escolher Ficheiro</b>
                            </label>
                            <input type="file" className="form-control" id="program"
                                required onChange={(e) => setProgram(e.target.files[0])}/>
                            <span className="file-message">
                                    {program ? <u>{program.name}</u> : 'Nenhum arquivo selecionado.'}
                            </span>
                            <div className="invalid-feedback" id="program-feedback">
                              Programa é obrigatório e tem que ser um pdf!
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="showSponsors">Escolhe os organizadores</label>
                      {
                        props.sponsors.map((sponsor, index) => {
                          return (
                            <div className="form-check" key={sponsor.id}>
                              <input className="form-check-input" type="checkbox" value={sponsor} id="defaultCheck2"
                                     checked={checkedState[index]}
                                     onChange={() => handleOnChange(index)}/>
                              <label htmlFor="defaultCheck2">
                                {sponsor.id}
                              </label>
                            </div>
                          )
                        })
                      }
                    <div className="invalid-feedback" id="sponsor-feedback">
                      Tem que ser selecionado, pelo menos, 1 organizador!
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row justify-content-center">
                      {
                        checkedState.map((state, index) => {
                          if(state === true) {
                            return (
                              checkedState.length % 3 === 0 ? <div className="col-md-3 col-sm-6 organizadores" key={props.sponsors[index].id}>
                                <Sponsor data={props.sponsors[index]} />
                              </div> : <div className="col-md-4 col-sm-6 organizadores" key={props.sponsors[index].id}>
                                <Sponsor data={props.sponsors[index]} />
                              </div>

                            )
                          }
                        })
                      }
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row d-flex justify-content-center actions-div">
                      <div className="buttons-div">
                        <button type="submit" className="btn modal-btn" >
                          <strong>Adicionar</strong>
                        </button>
                        {modalOpen ? <OfficeModal setModalOpen={setModalOpen}
                                                  title={modalInfo.title}
                                                  text={modalInfo.text}
                                                  actionFunc={modalInfo.actionFunc}
                        /> : null}
                      </div>
                      <div className="buttons-div">
                        <button type="button" className="btn modal-btn" onClick={() => history.push('/backOffice')}>
                          <strong>Cancelar</strong>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default AddFormation;
import Footer from '../components/footer';
import OfficeModal from '../components/officeModal';
import { useEffect, useState } from 'react';
import { deleteSponsor, updateSponsor, uploadFilesSponsorToStorage } from '../utils/firebaseUtils';
import { useHistory } from 'react-router-dom';
import * as firebase from '../utils/firebaseUtils';

function EditSponsor(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [description, setDescription] = useState(props.data.description);
  const [error, setError] = useState('');
  const [image, setImage] = useState({});
  const history = useHistory();

  useEffect(() => {
    const getImage = async () => {
      firebase.storage
        .ref(props.data.img)
        .getDownloadURL()
        .then(url => {
          setImage(url);
        });
    };

    getImage();
  }, []);

  const handleDelete = e => {
    e.preventDefault();
    setModalInfo({
      title: 'Eliminar Organizador',
      text: 'Tens a certeza que queres eliminar o organizador?',
      actionFunc: remove
    });
    setModalOpen(true);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (description === props.data.description && typeof image === 'string') {
      setError('Não alteraste nada!');
    } else {
      setError('');
      const forms = document.getElementsByClassName('needs-validation');
      Array.prototype.filter.call(
        forms,
        async function (form) {
          if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
          } else if (
            typeof image !== 'string' &&
            image.type.split('/')[0] !== 'image'
          ) {
            document.getElementById('image').style.borderColor = '#dc3545';
            document.getElementById('image-feedback').style.display = 'inline';
            e.preventDefault();
            e.stopPropagation();
          } else if (
            !document
              .getElementById('smallDesc')
              .value.match('^(?!.*<[^>]+>).*')
          ) {
            document.getElementById('smallDesc').style.borderColor = '#dc3545';
            document.getElementById('smallDesc-feedback').style.display =
              'inline';
            e.preventDefault();
            e.stopPropagation();
          } else {
            setModalInfo({
              title: 'Editar Organizador',
              text: 'Tens a certeza que queres guardar as alterações?',
              actionFunc: edit
            });
            setModalOpen(true);
          }
          form.classList.add('was-validated');
        },
        false
      );
    }
  };

  const edit = async () => {
    const sponsor = {
      id: props.data.id,
      description: description,
      img: props.data.img
    };
    if (typeof image !== 'string') {
      uploadFilesSponsorToStorage(props.data.img, image);
    }
    await updateSponsor(sponsor);
    history.push('/organizadores');
  };

  const remove = async () => {
    await deleteSponsor(props.data.id);
    history.push('/organizadores');
  };

  return (
    <div>
      <div className="container context gradient-container" id="addSponsor-page">
        <div className="row align-items-center justify-content-center">
        <div className={`col-md-8 col-12 formacao-intro`}>
            <div className="container-addSponsor">
              <form
                className='needs-validation'
                noValidate={true}
                id='editSponsorForm'
                onSubmit={e => handleSubmit(e)}
              >
                <div className='form-group'>
                  <div className='row d-flex'>
                    <h2 className="edit-sponsor-title">Editar Organizador</h2>
                  </div>
                  {error && (
                    <div className='alert alert-warning col-md-3'>{error}</div>
                  )}
                </div>
                <div className='form-group'>
                  <div className='row d-flex justify-content-center'>
                    <div className='col label-input'>
                      <label htmlFor='name'>Nome</label>
                      <input
                        type='text'
                        className='form-control'
                        id='name'
                        placeholder='Nome do organizador'
                        defaultValue={props.data.id}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='row d-flex justify-content-center'>
                    <div className='col label-input'>
                      <label htmlFor='smallDesc'>Descrição</label>
                      <textarea
                        className='form-control descricao'
                        id='smallDesc'
                        rows='6'
                        defaultValue={props.data.description}
                        placeholder='Pequena Descrição'
                        onChange={e => setDescription(e.target.value)}
                        required
                      />
                      <div className='invalid-feedback' id='smallDesc-feedback'>
                        Descrição é obrigatória e não pode conter tags de HTML!
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className='form-group'>
                  <div className='row d-flex justify-content-center'>
                    <div className='col label-input'>
                      <label htmlFor='image'>Imagem: </label>
                      <a
                        className={
                          'programa highlight-link highlight-link-' +
                          props.color
                        }
                        href={image}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <strong> imagem</strong>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='row d-flex justify-content-center'>
                    <div className='col label-input'>
                      <label htmlFor='image'>Escolher outra imagem: </label>
                      <input
                        type='file'
                        className='form-control'
                        id='image'
                        onChange={e => setImage(e.target.files[0])}
                      />
                      <div className='invalid-feedback' id='image-feedback'>
                        Imagem é obrigatória!
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="buttons-container">
        <div className='container context gradient-container' id='editSponsor-page'>
          <div className="buttons-wrapper">
            <div className='edit-sponsor'>
              <div className=''>
                <div className='row d-flex justify-content-center actions-div'>
                  <div className='col-md-4 mt-2 label-input justify-content-center'>
                    <button type='submit' className='btn modal-btn'>
                      <strong>Guardar Alterações</strong>
                    </button>
                  </div>
                  <div className='col-md-4 mt-2 label-input justify-content-center'>
                    <button
                      type='button'
                      className='btn modal-btn'
                      onClick={e => handleDelete(e)}
                    >
                      <strong>Eliminar Organizador</strong>
                    </button>
                    {modalOpen ? (
                      <OfficeModal
                        setModalOpen={setModalOpen}
                        title={modalInfo.title}
                        text={modalInfo.text}
                        actionFunc={modalInfo.actionFunc}
                      />
                    ) : null}
                  </div>
                  <div className='col-md-4 mt-2 label-input justify-content-center'>
                    <button
                      type='button'
                      className='btn modal-btn'
                      onClick={() => history.push('/organizadores')}
                    >
                      <strong>Cancelar</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSponsor;

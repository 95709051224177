import Footer from '../components/footer'
import { useAuth } from '../contexts/AuthContext'
import { Redirect, useHistory } from 'react-router-dom'
import { useRef, useState } from 'react'

function Login () {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login, resetPassword } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { currentUser } = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const [resetEmail, setResetEmail] = useState('')
  const [showReset, setShowReset] = useState(false)

  if (currentUser === null) {
    async function handleSubmit (e) {
      e.preventDefault()
      try {
        setError('')
        setLoading(true)
        await login(emailRef.current.value, passwordRef.current.value)
        history.push('/backOffice')
      } catch {
        setError('Email ou palavra-passe incorretos.')
      }

      setLoading(false)
    }
    function toggleShowPassword () {
      setShowPassword(!showPassword)
    }

    const handlePasswordReset = async () => {
      if (!resetEmail) {
        setError(
          'Por favor introduza o seu email para redefinir a palavra-passe.'
        )
        return
      }
      try {
        setError('')
        setLoading(true)
        await resetPassword(resetEmail)
        setError('Verifique o seu email para as próximas instruções.')
      } catch {
        setError('Erro ao redefinir a palavra-passe.')
      }
      setLoading(false)
    }

    return (
      <div>
        <div className='container context gradient-container' id='login-page'>
          <div className='d-flex align-self-center justify-content-center w-100'>
            <div class='col-xl-5 col-md-8 mt-5' id='login-container'>
              <div className='login-title-container'>
                <h2 className='loginTitle'>
                  <strong>
                    {showReset ? 'Redefinir palavra-passe' : 'Login'}
                  </strong>
                </h2>
                {error && <div className='alert alert-danger'>{error}</div>}
              </div>
              <div className='center'>
                {showReset ? (
                  <>
                    <input
                      type='email'
                      className='form-control'
                      placeholder='Introduza o seu email'
                      value={resetEmail}
                      onChange={e => setResetEmail(e.target.value)}
                      required
                    />
                    <div className="center-buttons">
                      <button
                        disabled={loading}
                        onClick={handlePasswordReset}
                        className='btn'
                      >
                        Enviar email de redefinição
                      </button>
                      <button onClick={() => setShowReset(false)} className='btn'>
                        Voltar para o Login
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className='input-group col-lg-12'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text bg-white px-4 border-md border-right-0'>
                            <i className='fa fa-envelope' />
                          </span>
                        </div>
                        <input
                          id='email'
                          type='email'
                          name='email'
                          placeholder='Email address'
                          ref={emailRef}
                          className='form-control bg-white border-left-0 border-md'
                          required
                        />
                      </div>
                      <div className='input-group col-lg-12'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text bg-white px-4 border-md border-right-0'>
                            <i class='fa fa-lock' />
                          </span>
                        </div>
                        <input
                          id='password'
                          type={showPassword ? 'text' : 'password'}
                          name='password'
                          placeholder='Password'
                          ref={passwordRef}
                          autoComplete='on'
                          className='form-control bg-white border-left-0 border-md'
                          required
                        />
                        <div className=''>
                          <div
                            className=''
                            onClick={toggleShowPassword}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className='botao'>
                              <i
                                className={`fa ${
                                  showPassword ? 'fa-eye-slash' : 'fa-eye'
                                } text-muted`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="buttons-lgn-rdf">
                        <button
                          disabled={loading}
                          type='submit'
                          className='btn btn-primary'
                        >
                          Login
                        </button>
                        <button
                          onClick={() => setShowReset(true)}
                          className='btn-reset'
                        >
                          Redefinir palavra-passe
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  } else {
    return <Redirect to='/backOffice' />
  }
}

export default Login

import Footer from "../components/footer";
import {useEffect, useState} from "react";
import { useHistory } from "react-router";
import {db, storage} from "../utils/firebaseUtils";
import { query } from "firebase/firestore";
import * as XLSX from 'xlsx';

function Participants() {
  const [formations, setFormations] = useState([]);
  const formURL = decodeURIComponent(window.location.pathname.substring(14));
  const [participants, setParticipants] = useState([]);
  const [pdfLinks, setPdfLinks] = useState({});

  useEffect(() => {
    const getFormations = async () => {
      const formationRef = db.collection('formations');

      const snapshot = await formationRef.where('url','==', formURL).get();
      setFormations(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }

    getFormations();
  }, []);

  useEffect(() => {
    const getParticipants = async () => {
      let idValue = 0;
      if (formations[0] !== undefined) {
        idValue = formations[0].id;
      }
      const registrationRef = db.collection('formations/' + idValue + '/enrolled').orderBy("registrationExpiration", "desc");
      registrationRef.get().then(async (querySnapshot) => {
        const updatedParticipants = [];
        const pdfLinksTemp = {};

        for (let doc of querySnapshot.docs) {
          const participantData = { id: doc.id, ...doc.data() };
          updatedParticipants.push(participantData);

          // Fetch PDF link for each participant
          try {
            const pdfRef = storage.ref(`formations-storage/${idValue}/${participantData.email}`);
            const pdfUrl = await pdfRef.getDownloadURL();
            pdfLinksTemp[participantData.email] = pdfUrl;
          } catch (error) {
            console.error(`No PDF found for ${participantData.email}:`, error);
            pdfLinksTemp[participantData.email] = null;
          }
        }

        setParticipants(updatedParticipants);
        setPdfLinks(pdfLinksTemp);
      });
    }
    formations !== [] && getParticipants()
  }, [formations]);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(participants);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Participants");
    XLSX.writeFile(workbook, 'Participantes ' + formations[0].name + '.xlsx');    
  };

  return (
    <>
      <div className="container context gradient-container" id="backoffice-page-participants">
         <h2 className="title">Participantes</h2>
          <div className=" justify-content-center g-0">
          <button className="button-participantes" onClick={exportToExcel}>Exportar para Excel</button>
          <div className="row justify-content-center mb-5 table-responsive tablediv">
            <table className="table" id="participantsTable">
              <thead>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Nº UP</th>
                <th scope="col">Email</th>
                <th scope="col">Nº Tel.</th>
                <th scope="col">Comprovativo</th>
                <th scope="col">CC</th>
                <th scope="col">Validade CC</th>
                <th scope="col">Data de Nascimento</th>
                <th scope="col">Curso</th>
                <th scope="col">Ano</th>
              </tr>
              </thead>
              <tbody>
                {
                  participants !== [] && participants.map((part) => {
                    const date = part.registrationExpiration ? new Date(part.registrationExpiration) : null
                    return(
                      <tr key={part.id}>
                        <td>{part.fullName}</td>
                        <td>{part.studentNumber}</td>
                        <td>{part.email}</td>
                        <td>{part.phoneNumber}</td>
                        <td>
                          {pdfLinks[part.email] ? (
                            <a 
                              href={pdfLinks[part.email]} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="btn btn-sm btn-outline-primary"
                            >
                              Ver Comprovativo
                            </a>
                          ) : (
                            <span>Sem Comprovativo</span>
                          )}
                        </td>
                        <td>{part.idCardNumber}</td>
                        <td>{part.idCardExpirationDate}</td>
                        <td>{part.birthDate}</td>
                        <td>{part.course}</td>
                        <td>{part.year}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Participants;
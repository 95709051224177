import React from 'react'
import '../css/NotFound.css'

const NotFound = () => {
  return (
    <div className='not-found'>
      <div className='not-found-content'>
        <h1>404</h1>
        <p>Page not found.</p>
        <a href='/'><div><i class="bi bi-arrow-left"></i> Return to Home</div></a>
      </div>
    </div>
  )
}

export default NotFound

import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from "../contexts/AuthContext";

function NavBar() {
  const history = useHistory();
  const location = useLocation(); // Get the current location
  const { currentUser, logout } = useAuth();

  async function handleLogout() {
    try {
      await logout();
      history.push("/login");
    } catch {
      console.log("error");
    }
  }

  return (
    <>
      <nav className="navbar navbar-transparent navbar-top navbar-expand-md" role="navigation" id="navbar">
        <div className={currentUser ? "container" : "container container-auth"}>
          {currentUser ? (
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">&#9776;</span>
            </button>
          ) : null}
          <div className="logo-container">
            <div className={currentUser ? "logo logo-auth" : "logo"}>
              <button
                className={!currentUser ? "logo-btn logo-btn-auth" : "logo-btn"}
                onClick={() => history.push('/')}
              >
                <img src="img/logo_preto.png" alt="JuniFEUP logo" />
              </button>
            </div>
          </div>
          {currentUser ? (
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mx-auto"> {/* Use mx-auto to center the navbar links */}
                <div className="middle">
                  <li className={`nav-item ${location.pathname === '/backOffice' ? 'active' : ''}`}>
                    <a className="nav-link" onClick={() => history.push('/backOffice')}>Formações</a>
                  </li>
                  <li className={`nav-item ${location.pathname === '/organizadores' ? 'active' : ''}`}>
                    <a className="nav-link" onClick={() => history.push('/organizadores')}>Organizadores</a>
                  </li>
                </div>
                <li className="nav-item">
                  <button className="">
                    <a className="loggout-btn" onClick={handleLogout}>Log Out</a>
                  </button>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </nav>
    </>
  );
}

export default NavBar;
